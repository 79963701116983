<script lang="ts" setup>
import type { LinkItem } from "~~/models/links";
import type { ImageFileField, TeaserCarouselSectionRecord, TeaserRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";

const { data } = defineProps<{ data: TeaserCarouselSectionRecord; currentPageId: string }>();

const activeTeaser = ref<TeaserRecord>(data.teasers?.at(0) ?? {} as TeaserRecord);

const localePath = useLocalePath();

const activeTeaserUrl = computed<LinkItem>(() => {
  return getLinkForType(activeTeaser.value.link, localePath);
});

function updateActiveTeaser(activeIndex: number) {
  activeTeaser.value = data.teasers.at(activeIndex)!;
}

useSwiper();
</script>

<template>
  <div :data-variant="data.variant ? 'full' : 'side'" class="bg-[--color-wrapper-bg] py-[56px] text-[--color-wrapper-text]">
    <UContainer>
      <div class="gap-lg md:gap-3xl flex flex-col" :class="{ 'md:flex-row': !data.variant }">
        <div class="gap-lg md:gap-xl flex flex-col" :class="{ 'order-1 sm:ml-auto sm:max-w-[400px]': !data.variant }">
          <SectionTitle v-if="data.variant" as="h2" :label="data.sectionTitle" />

          <ClientOnly>
            <SwiperCarousel
              v-slot="{ item }: { item: TeaserRecord }"
              :items="data.teasers"
              :slides-per-view="1"
              :navigation="true"
              :nav-position="data.variant ? 'center-outer' : 'bottom-right'"
              @slide-change="updateActiveTeaser"
            >
              <NuxtLink
                :to="activeTeaserUrl.href ?? '/'"
                :target="activeTeaserUrl.target ?? '_self'"
              >
                <div class="slider-item-container">
                  <div class="slider-item-container__image h-[524px]">
                    <DatocmsImage
                      v-if="item.image as ImageFileField"
                      :data="item.image?.responsiveImage as ResponsiveImageType"
                      object-fit="cover"
                      :fade-in-duration="0"
                      :use-placeholder="false"
                      style="position: unset"
                    />
                  </div>

                  <div v-if="data.variant" class="slider-item-container__overlay overlay z-[1]" />
                </div>
              </NuxtLink>
            </SwiperCarousel>
          </ClientOnly>
        </div>

        <div class="flex flex-col justify-between" :class="{ 'w-full md:w-1/2': !data.variant }">
          <SectionTitle v-if="!data.variant" as="h2" :label="data.sectionTitle" color="primary" class="mb-lg" />

          <NuxtLink
            :to="activeTeaserUrl.href ?? '/'"
            :target="activeTeaserUrl.target ?? '_self'"
            class="hover:no-underline"
          >
            <div class="gap-xs flex flex-col items-start">
              <span
                class="leading-p-150 text-2xl uppercase text-inherit md:text-3xl [&_em]:text-[--color-accent] [&_p]:mb-0"
                v-html="activeTeaser.title"
              />

              <span v-if="activeTeaser.optText" class="text-[--color-teaser-text]" v-html="activeTeaser.optText" />

              <UButton
                variant="ghost" icon="i-ph-arrow-right" trailing
                :color="data.variant ? 'white' : 'primary'"
                :class="data.variant ? 'border-b-gradient-white' : 'border-b-gradient-primary'"
              >
                {{ activeTeaser.link.text }}
              </UButton>
            </div>
          </NuxtLink>
        </div>
      </div>
    </UContainer>
  </div>
</template>

<style scoped>
.slider-item-container {
  display: grid;
  grid-template-areas: "stack";

  .slider-item-container__image {
    grid-area: stack;
  }

  .slider-item-container__overlay {
    grid-area: stack;
  }
}

.overlay::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  background: linear-gradient(0deg, rgb(var(--color-black) / 0.8) 0%, rgb(var(--color-black) / 0.1) 100%);
}

:root {
  .sf-hangcha {
    [data-variant="full"] {
      --color-wrapper-bg: rgb(var(--color-black));
      --color-wrapper-text: rgb(var(--color-white));
      /* currently hidden on full, as the design did not have an example of this */
      --color-teaser-text: rgb(var(--color-white));
      --color-accent: rgb(var(--color-dark-accent));
    }
    [data-variant="side"] {
      --color-wrapper-bg: rgb(var(--color-white));
      --color-wrapper-text: rgb(var(--color-primary));
      --color-teaser-text: rgb(var(--color-black));
      --color-accent: rgb(var(--color-primary));

      .swiper-block {
        width: 50%;
      }
    }
  }
}
</style>
